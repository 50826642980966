import { IPromise } from "angular";
import { Duration, Instant, LocalDate } from "js-joda";
import { match } from "ts-pattern";
import { apiErrors } from "../../../../scripts/consts/apiErrors.const";
import { Loadable, loadable } from "../../../../scripts/consts/loadable.const";
import {
  ComplianceQuestion,
  ComplianceReviewUploadedDocument,
  ReviewDocumentRecord,
} from "../../../../scripts/messages/compliance";
import { MfModalFactory } from "../../../../scripts/services/mfModalFactory";
import { CompService } from "../../comp.service";
import { formatDisplayComplianceQuestionAnswer } from "../../compliance.utils";
import "./compliance-document-review.component.scss";

interface ComponentOptions extends angular.IComponentOptions {
  $name: string;
}

//! @ngInject
class Controller implements ng.IComponentController {
  static readonly $name = "ComplianceDocumentReviewScreen";

  document: Loadable<ReviewDocumentRecord> = loadable.loading();
  questions: Loadable<ComplianceQuestion[]> = loadable.loading();
  uploadedDocuments: Loadable<ComplianceReviewUploadedDocument[]> = loadable.loading();
  activeQuestion: Loadable<ComplianceQuestion> = loadable.loading();
  questionAnswer: unknown = null;
  isCountDownActive = true;
  countdownTargetDate: Loadable<Instant> = loadable.loading();
  questionsEnd = false;
  countdownText = "";
  countDownInterval?: IPromise<any>;

  constructor(
    private compService: CompService,
    private mfModal: MfModalFactory,
    private $interval: angular.IIntervalService
  ) {}

  $onInit(): void {
    this.fetchData();
  }

  canGoToNextQuestion(): boolean {
    if (!loadable.isResolved(this.activeQuestion)) {
      return false;
    }

    return this.questionAnswer !== null || !this.activeQuestion.value.isMandatory;
  }

  canGoToPreviousQuestion(): boolean {
    if (!loadable.isResolved(this.questions) || !loadable.isResolved(this.activeQuestion)) {
      return false;
    }

    return this.activeQuestion.value.id !== this.questions.value[0].id;
  }

  getNextQuestionOrNull(): ComplianceQuestion | null {
    return match({ questions: this.questions, activeQuestion: this.activeQuestion })
      .with({ questions: { type: "Resolved" }, activeQuestion: { type: "Resolved" } }, (x) => {
        const questionIds = x.questions.value.map((question) => question.id);
        return x.questions.value[questionIds.indexOf(x.activeQuestion.value.id) + 1] ?? null;
      })
      .otherwise(() => null);
  }

  getPreviousQuestionOrNull(): ComplianceQuestion | null {
    return match({ questions: this.questions, activeQuestion: this.activeQuestion })
      .with({ questions: { type: "Resolved" }, activeQuestion: { type: "Resolved" } }, (x) => {
        const questionIds = x.questions.value.map((question) => question.id);
        return x.questions.value[questionIds.indexOf(x.activeQuestion.value.id) - 1] ?? null;
      })
      .otherwise(() => null);
  }

  goToNextQuestion() {
    if (!loadable.isResolved(this.document) || !loadable.isResolved(this.activeQuestion)) {
      console.error("row is not resolved");
      return;
    }

    this.questions = loadable.loading();

    this.compService
      .answerComplianceDocumentReviewQuestion({
        complianceReviewRecordId: this.document.value.id,
        questionId: this.activeQuestion.value.id,
        value: this.parseQuestionValue(this.activeQuestion.value, this.questionAnswer),
      })
      .then((reviewDocumentResponse) => {
        this.questions = loadable.resolve(reviewDocumentResponse.questions);

        const nextQuestion = this.getNextQuestionOrNull();

        if (nextQuestion !== null) {
          this.activeQuestion = loadable.resolve(nextQuestion);
          this.questionAnswer = this.loadAnswer(this.activeQuestion.value);
        } else {
          this.questionsEnd = this.questions.value.every((question) => question.value !== null);
        }
      })
      .catch((e) => this.promptErrorModal(e));
  }

  parseQuestionValue(question: ComplianceQuestion, questionAnswer: unknown) {
    switch (question.type) {
      case "date":
        return (questionAnswer as LocalDate).toString();
      default:
        return typeof questionAnswer === "number" || typeof questionAnswer === "string"
          ? questionAnswer.toString()
          : null;
    }
  }

  goToPreviousQuestion() {
    if (!loadable.isResolved(this.document) || !loadable.isResolved(this.activeQuestion)) {
      console.error("row is not resolved");
      return;
    }

    const previousQuestion = this.getPreviousQuestionOrNull();

    if (previousQuestion !== null) {
      this.activeQuestion = loadable.resolve(previousQuestion);
    } else {
      this.activeQuestion = loadable.resolve(this.questions[0]);
    }

    this.questionAnswer = this.loadAnswer(this.activeQuestion.value);
  }

  loadAnswer(question: ComplianceQuestion) {
    if (!loadable.isResolved(this.activeQuestion)) {
      console.error("row is not resolved");
      return null;
    }

    return question.value;
  }

  optionIsAnswer(optionId) {
    if (!loadable.isResolved(this.activeQuestion)) {
      return;
    }

    return optionId === this.activeQuestion.value.value;
  }

  completeReview() {
    if (!loadable.isResolved(this.document)) {
      console.error("row is not resolved");
      return;
    }

    const documentId = this.document.value.id;
    this.document = loadable.loading();
    this.questions = loadable.loading();

    this.compService
      .completeComplianceDocumentReview({ complianceReviewRecordId: documentId })
      .then(() => this.fetchData())
      .catch((e) => this.promptErrorModal(e));
  }

  backFromCompleteReview() {
    this.questionsEnd = false;
  }

  answerDropdownQuestion(value: string) {
    this.questionAnswer = value;
  }

  questionAnswerSumDisplay(question: ComplianceQuestion) {
    return formatDisplayComplianceQuestionAnswer(question);
  }

  promptErrorModal(error: Error) {
    const modal = this.mfModal.create({
      variant: "danger",
      subject: "Error",
      message: apiErrors.format(error),
      confirmLabel: "Close",
      onConfirm: () => modal.close(),
      hideCancelButton: true,
    });
  }

  renewSession() {
    this.fetchData();
  }

  formatCountdownText(countdownTargetDate: Instant) {
    const seconds = Math.max(Duration.between(Instant.now(), countdownTargetDate).seconds(), 0);
    const minutes = String(Math.floor(seconds / 60)).padStart(2, "0");
    const remainingSeconds = String(seconds % 60).padStart(2, "0");

    return `${minutes}:${remainingSeconds}`;
  }

  private async fetchData() {
    this.compService
      .fetchComplianceDocumentReview()
      .then((reviewDocumentResponse) => {
        this.questionsEnd = false;
        this.document = loadable.resolve(reviewDocumentResponse.document);
        this.questions = loadable.resolve(reviewDocumentResponse.questions);
        this.uploadedDocuments = loadable.resolve(reviewDocumentResponse.uploadedDocuments);
        this.activeQuestion = loadable.resolve(
          this.getRelevantQuestion(reviewDocumentResponse.questions)
        );
        this.questionAnswer = this.loadAnswer(this.activeQuestion.value);
        this.countdownTargetDate = loadable.resolve(Instant.now().plusSeconds(reviewDocumentResponse.document.ttl));
        this.countDownInterval = this.$interval(() => this.tickCountdown(), 1000);
      })
      .catch((e) => {
        this.document = loadable.reject(e);
        this.promptErrorModal(e);
      });
  }

  private tickCountdown() {
    if (!loadable.isResolved(this.countdownTargetDate)) {
      return;
    }

    if (!this.countdownTargetDate.value.isAfter(Instant.now()) && this.countDownInterval !== undefined) {
      this.$interval.cancel(this.countDownInterval);
      this.isCountDownActive = false;
      return;
    }
  }

  private getRelevantQuestion(questions: ComplianceQuestion[]) {
    const question = questions.find((q) => q.value === null) ?? questions[questions.length - 1];

    if (question.value !== null) {
      this.questionsEnd = true;
    }

    return question;
  }
}

export const ComplianceDocumentReviewScreenComponent: ComponentOptions = {
  $name: "complianceDocumentReviewScreen",
  templateUrl:
    "admin/modules/compliance/components/compliance-document-review/compliance-document-review.component.html",
  controller: Controller,
  controllerAs: "ctrl",
};
