import Immutable from "immutable";
import React from "react";

export type QueryParams<$QueryParams> = {
  params: Immutable.Map<keyof $QueryParams, unknown>;
  getValue: <K extends keyof $QueryParams>(key: K) => $QueryParams[K] | undefined;
  getValueOrNull: <K extends keyof $QueryParams>(key: K) => NonNullable<$QueryParams[K]> | null;
  setValue: <K extends keyof $QueryParams>(key: K, value: $QueryParams[K]) => void;
};

export function useQueryParams<$QueryParams extends { [key: string]: unknown }>(
  params?: Partial<{ defaultOptions: $QueryParams }>
): QueryParams<$QueryParams> {
  const [queryParams, setQueryParams] = React.useState<
    Immutable.Map<keyof $QueryParams, $QueryParams[number]>
  >(() => {
    if (params?.defaultOptions !== undefined) {
      return Immutable.Map(params.defaultOptions as any);
    }

    return Immutable.Map();
  });

  const setValue = <T extends keyof $QueryParams>(key: T, value: $QueryParams[T] | undefined) => {
    value === undefined
      ? setQueryParams((params) => params.delete(key))
      : setQueryParams((params) => params.set(key, value as any));
  };

  const getValue = <T extends keyof $QueryParams>(key: T) => {
    return queryParams.get(key) as $QueryParams[T] | undefined;
  };

  const getValueOrNull = <T extends keyof $QueryParams>(key: T) => {
    return (queryParams.get(key) ?? null) as NonNullable<$QueryParams[T]> | null;
  };

  return { params: queryParams, setValue, getValue, getValueOrNull };
}
