import { Box, Image } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Shimmer } from "react-shimmer";
import { queryKeys } from "../query-keys";

export const TextShimmer = styled(Shimmer)`
  border-radius: 6px;
  margin: 2px 0;
`;

export const CircleShimmer = styled(Shimmer)`
  border-radius: 50%;
`;

export const ImageCircleShimmer = (props: { size: number; src: string }) => {
  const { isLoading, data } = useQuery({
    queryKey: queryKeys.shared.image(props.src),
    staleTime: Infinity,
    queryFn: async () => {
      try {
        const response = await fetch(props.src);
        const blob = await response.blob();
        return URL.createObjectURL(blob);
      } catch {
        console.log(`failed to fetch image: ${props.src}.`);
        return props.src;
      }
    },
  });

  const showShimmer = isLoading || data === undefined;

  return (
    <Box w={props.size} h={props.size} borderRadius="50%">
      {showShimmer ? (
        <CircleShimmer width={props.size} height={props.size} />
      ) : (
        <Image src={data} w={props.size} h={props.size} borderRadius="50%" />
      )}
    </Box>
  );
};
