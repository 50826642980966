import { Image } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Shimmer } from "react-shimmer";
import { queryKeys } from "../query-keys";

interface Props {
  src: string;
  width: number;
  height: number;
}

const CachedSignedImage = (props: Props) => {
  const actualUrl = props.src.split("?")[0];

  const query = useQuery({
    queryKey: queryKeys.shared.image(actualUrl),
    queryFn: async () => {
      if (props.src.startsWith("blob:")) {
        return props.src;
      }

      const response = await fetch(props.src);
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    },
    staleTime: Infinity,
  });

  if (query.isLoading) {
    return <Shimmer width={props.width} height={props.height} />;
  }

  if (query.data !== undefined) {
    return <Image src={query.data} w={props.width} h={props.height} borderRadius="lg" />;
  }

  return null;
};

export default CachedSignedImage;
