import { Flex } from "@chakra-ui/react";
import React from "react";
import { Messages } from "../../../core/api";
import { isCommCenterMessageAuthorTheSame } from "../utils/communication-utils";
import ChatMessage from "./ChatMessage";
import ChatMessageInput from "./ChatMessageInput";
import ClosedChatMessage from "./ClosedChatMessage";

interface Props {
  activeTicket: Messages["CommCenterTicket"];
  onSubmitNewMessage: (message: string) => void;
}

const ChatMessages = (props: Props) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight;
    }
  }, [props.activeTicket.messages]);

  return (
    <Flex
      direction="column"
      justifyContent="space-between"
      backgroundImage={`linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),
    url(/admin/images/chat.jpg)`}
      backgroundSize="60%"
      borderRadius="0 12px 12px 0"
      height="100%"
    >
      <Flex direction="column" ref={wrapperRef} gap={2} p={4} overflowY="auto" height="100%">
        {props.activeTicket.messages.map((message, i) => {
          const isTheSameAuthor = isCommCenterMessageAuthorTheSame({
            previous: props.activeTicket.messages[i - 1],
            next: message,
          });

          return (
            <ChatMessage
              key={`${message.id}`}
              message={message}
              displayEntityName={!isTheSameAuthor}
            />
          );
        })}
      </Flex>
      {props.activeTicket.status !== "RESOLVED" ? (
        <ChatMessageInput onSubmit={props.onSubmitNewMessage} />
      ) : (
        <ClosedChatMessage isOpen={props.activeTicket.status === "RESOLVED"} />
      )}
    </Flex>
  );
};

export default ChatMessages;
